<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link class="brand-logo">
        <vuexy-logo />
        <h2 class="brand-text text-primary ml-1">
          {{ appName }}
        </h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Login V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-1"
          >
            Create A Company
          </b-card-title>
          <b-card-text class="mb-2">
            Please create a company to continue.
          </b-card-text>

          <!-- form -->
          <validation-observer
            ref="resourceForm"
            #default="{invalid, dirty}"
          >
            <b-form @submit.prevent="createCompany">
              <b-row>
                <!-- name -->
                <b-col cols="12">
                  <b-form-group
                    label="Name"
                    label-for="name"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Name"
                      rules="required"
                      vid="name"
                    >
                      <b-form-input
                        id="name"
                        v-model="companyName"
                        name="name"
                        :state="errors.length > 0 ? false:null"
                        placeholder="company name"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <!-- submit -->
                <b-col cols="12">
                  <b-button
                    type="submit"
                    variant="primary"
                    block
                    :disabled="invalid || !dirty"
                  >
                    Create
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>

        </b-col>
      </b-col>
    <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BRow, BCol, BLink, BFormGroup, BFormInput, BCardText, BCardTitle, BImg, BForm, BButton,
} from 'bootstrap-vue'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useJwt from '@/auth/jwt/useJwt'
import store from '@/store/index'

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    VuexyLogo,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      resourceName: 'Company',
      resourceNamePlural: 'Companies',
      companyName: '',
      sideImg: require('@/assets/images/pages/login-v2.svg'),
      required,
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    createCompany() {
      this.$refs.resourceForm.validate().then(success => {
        if (success) {
          // reset form validation state
          // so we can track if form data is dirty
          this.$refs.resourceForm.reset()
          useJwt.createCompany({
            name: this.companyName,
          })
            .then(response => {
              const resourceData = response.data.data

              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: `${resourceData.name} Created`,
                  icon: 'CoffeeIcon',
                  variant: 'success',
                  text: 'Company created successfully.',
                },
              })
              this.$router.push({ name: 'dashboard' })
            })
            .catch(error => {
              if (error.response.status === 400) {
                // highlights all applicable erroneous inputs mentioned by the server.
                this.$refs.resourceForm.setErrors(error.response.data.error)
              }
            })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
